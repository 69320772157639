import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { ElasticButton } from '@app/shared'
import { ElasticService } from '@app/api'

interface props {}

const CheckElastic: React.FC<props> = () => {
  return (
    <ElasticButton
      icon={<InfoCircleOutlined />}
      mutateFunction={ElasticService.healthCheck}
      successText={'Elastic Search работает'}
      errorText={'Ошибка! Возможно, elastic search не работает'}
    >
      Проверка Elastic Search
    </ElasticButton>
  )
}

export default CheckElastic
