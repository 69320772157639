import React from 'react'
import {
  IStaticContentResponse,
  IVacanciesPage,
  StaticContentType,
} from '@app/interfaces/static-content'
import { useStaticContent } from '@app/hooks/query/static-content'
import { Button, Col, Form, Input, message, PageHeader, Row, Skeleton, Typography } from 'antd'
import {
  DeleteFormListBlock,
  ErrorBoundary,
  FeedbackFormRadio,
  FormButtons,
  FormFileManagerSelect,
  FormListMovingArrows,
  FormSeoInputs,
  ImageWithFallback,
  SiteLink,
  TextEditor,
} from '@app/shared'
import { useMutation } from 'react-query'
import { StaticContentService } from '@app/api'
import { PlusCircleOutlined } from '@ant-design/icons'
import { getStaticPageImagePath } from '@app/utils/getStaticPageImagePath'

interface props {}

const VacanciesPage: React.FC<props> = () => {
  const [form] = Form.useForm()
  const pageType: StaticContentType = 'about_vacancy'
  const { isLoading, isError, data } =
    useStaticContent<IStaticContentResponse<IVacanciesPage>>(pageType)
  const update = useMutation(StaticContentService.updateLayout, {
    onSuccess: () => {
      message.success('Контент страницы успешно обновлен')
    },
    onError: () => {
      message.error('Ошибка обновления контента страницы')
    },
  })

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const onFinish = (data: IVacanciesPage) => update.mutateAsync({ pageType, data })

  return (
    <React.Fragment>
      <PageHeader
        title={'Вакансии'}
        extra={[<SiteLink key={1} entityType={'o-kompanii/vakansii'} />]}
      />
      <Form form={form} layout={'vertical'} onFinish={onFinish} initialValues={data?.data}>
        <Typography.Title level={4}>Заголовок H1</Typography.Title>
        <Form.Item name={['page', 'h1']}>
          <Input placeholder={'Введите заголовок H1'} />
        </Form.Item>
        <Typography.Title level={4}>SEO</Typography.Title>
        <FormSeoInputs isPageTitleRequired={false} />
        <Row gutter={[32, 32]}>
          <Col xs={24} xl={12}>
            <Form.Item name={'quote'} label={'Цитата'} rules={[{ required: true }]}>
              <TextEditor />
            </Form.Item>
          </Col>
          <Col xs={24} xl={12}>
            <Form.Item name={'additional_text'} label={'Дополнительный текст'}>
              <TextEditor />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Typography.Title level={4}>Преимущества работы в Bradex</Typography.Title>
            <Form.List name={'advantages'}>
              {(fields, { add, remove, move }) => (
                <Row gutter={[16, 16]}>
                  {fields.map(({ name, fieldKey, key }) => (
                    <Col key={key} xs={24} xl={8}>
                      <Form.Item>
                        <Row align={'middle'} justify={'space-between'}>
                          <FormListMovingArrows move={move} name={name} />
                          <DeleteFormListBlock remove={remove} name={name} />
                        </Row>
                      </Form.Item>
                      <Form.Item name={[name, 'image']} valuePropName={'src'}>
                        <ImageWithFallback height={50} />
                      </Form.Item>
                      <FormFileManagerSelect
                        formInstance={form}
                        pathFieldName={['advantages', name, 'image']}
                        label={'Изображение'}
                        fieldKey={fieldKey}
                        initialPath={getStaticPageImagePath(data?.data?.advantages[name].image)}
                      />

                      <Form.Item
                        fieldKey={fieldKey}
                        name={[name, 'label']}
                        label={'Текст'}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  ))}
                  <Col xs={24}>
                    <Button
                      type={'dashed'}
                      icon={<PlusCircleOutlined />}
                      onClick={() => add()}
                      block
                    >
                      Добавить преимущество
                    </Button>
                  </Col>
                </Row>
              )}
            </Form.List>
          </Col>
          <Col xs={24}>
            <FeedbackFormRadio />
          </Col>
          <Col xs={24}>
            <FormButtons isLoading={update.isLoading} />
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default VacanciesPage
