import React from 'react'
import { ISelection } from '@app/interfaces/selections'
import { Card, Col, Form, Input, Row } from 'antd'
import {
  FormButtons,
  FormContentInput,
  FormSelectCategories,
  FormSeoInputs,
  FormTitleWithSlugInputs,
  TextEditor,
} from '@app/shared'

interface props {
  isLoading: boolean
  submitFunction: (entity: ISelection) => void
  selection?: ISelection
}

const ProductSelectionForm: React.FC<props> = ({ isLoading, submitFunction, selection }) => {
  const [form] = Form.useForm()
  const onFinish = (values: ISelection) => {
    submitFunction({
      ...values,
      page: {
        ...values.page,
        content: values.page.content || '',
      },
    })
  }

  return (
    <Form form={form} onFinish={onFinish} initialValues={selection} layout={'vertical'}>
      <Form.Item hidden name={'id'}>
        <Input />
      </Form.Item>
      <Row gutter={[32, 32]}>
        <Col xs={24} lg={14}>
          <Card bordered={false} title={'Общая инофрмация'}>
            <FormTitleWithSlugInputs form={form} id={selection?.id} />
            <FormSelectCategories required={false} />
            <Form.Item name={['page', 'short_content']} label={'Короткое описание'}>
              <TextEditor placeholder={'Введите короткое описание'} />
            </Form.Item>
            <FormContentInput required={false} />
          </Card>
        </Col>
        <Col xs={24} lg={10}>
          <Card bordered={false} title={'SEO'}>
            <FormSeoInputs />
          </Card>
        </Col>
      </Row>
      <FormButtons isLoading={isLoading} />
    </Form>
  )
}

export default ProductSelectionForm
