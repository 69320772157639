import React from 'react'
import { Button, message } from 'antd'
import { useMutation } from 'react-query'
import { ExportService } from '@app/api'
import FileDownload from 'js-file-download'
import { AxiosResponse } from 'axios'
import { MailOutlined } from '@ant-design/icons'

interface props {}

const ExportMailingList: React.FC<props> = () => {
  const { isLoading, mutateAsync } = useMutation(ExportService.subscribers)

  const handleExportMailingList = () => {
    mutateAsync()
      .then((response: AxiosResponse<File>) => {
        FileDownload(response.data, 'Mailing-list-' + new Date().toLocaleDateString() + '.xlsx')
        message.success('Данные экспортированы')
      })
      .catch((e) => {
        message.error(e.response.data.message)
      })
  }

  return (
    <Button
      type={'default'}
      loading={isLoading}
      disabled={isLoading}
      onClick={handleExportMailingList}
      icon={<MailOutlined />}
    >
      Экспортировать почты рассылки
    </Button>
  )
}

export default ExportMailingList
