import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { RobotsService } from '@app/api'

export const ROBOTS_FILE_KEY = 'robots-file-key'

export function useRobotsFile(options?: UseQueryOptions<{ content: string }, Error>) {
  return useQuery<{ content: string }, Error>(
    ROBOTS_FILE_KEY,
    () =>
      RobotsService.getRobots().then(
        (response: AxiosResponse<{ content: string }>) => response.data,
      ),
    {
      ...options,
    },
  )
}
