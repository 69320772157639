import React from 'react'
import {
  CheckElastic,
  ImportCategories,
  ImportProducts,
  PageInfo,
  SpaceWithDivider,
} from '@app/shared'
import { Card, PageHeader } from 'antd'

interface props {}

const ElasticPage: React.FC<props> = () => {

  return (
    <React.Fragment>
      <PageInfo title={'Elastic Search'} />
      <PageHeader title={'Elastic Search'} />
			<Card>
				<SpaceWithDivider>
					<ImportProducts/>
					<ImportCategories/>
					<CheckElastic/>
				</SpaceWithDivider>
			</Card>
    </React.Fragment>
  )
}

export default ElasticPage
