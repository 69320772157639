import React from 'react'
import { Button, Col, Form, Input, PageHeader, Row, Skeleton, Typography } from 'antd'
import { useStaticContent } from '@app/hooks/query/static-content'
import {
  IDeliveryPricePage,
  IStaticContentResponse,
  StaticContentType,
} from '@app/interfaces/static-content'
import {
  DeleteFormListBlock,
  ErrorBoundary,
  FeedbackFormRadio,
  FormButtons,
  FormDatePicker,
  FormFileManagerSelect,
  FormListMovingArrows,
  FormSeoInputs,
  ImageWithFallback,
  SiteLink,
  TextEditor,
} from '@app/shared'
import { PlusCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useUpdateStaticContent } from '@app/hooks/useUpdateStaticContent'
import { getStaticPageImagePath } from '@app/utils/getStaticPageImagePath'

interface props {}

const DeliveryPaymentPage: React.FC<props> = () => {
  const [form] = Form.useForm()
  const pageType: StaticContentType = 'help_delivery'
  const { isLoading, isError, data } =
    useStaticContent<IStaticContentResponse<IDeliveryPricePage>>(pageType)

  const update = useUpdateStaticContent(pageType)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const onFinish = (data: IDeliveryPricePage) => update.mutateAsync({ pageType, data })

  return (
    <React.Fragment>
      <PageHeader
        title={'Доставка и оплата'}
        extra={[<SiteLink key={1} entityType={'servis/dostavka-i-oplata'} />]}
      />
      <Form
        form={form}
        onFinish={onFinish}
        layout={'vertical'}
        initialValues={{
          ...data?.data,
          tariff_validity: data?.data?.tariff_validity
            ? moment(data?.data?.tariff_validity)
            : undefined,
        }}
      >
        <Typography.Title level={4}>Заголовок H1</Typography.Title>
        <Form.Item name={['page', 'h1']}>
          <Input placeholder={'Введите заголовок H1'} />
        </Form.Item>
        <Typography.Title level={4}>SEO</Typography.Title>
        <FormSeoInputs isPageTitleRequired={false} />
        <Row gutter={[32, 32]}>
          <Col xs={24} xl={12}>
            <Typography.Title level={4}>Самовывоз со склада</Typography.Title>
            <Form.List name={'stocks'}>
              {(fields, { add, remove, move }) => (
                <React.Fragment>
                  {fields.map(({ name, key, fieldKey }) => (
                    <React.Fragment key={key}>
                      <Form.Item>
                        <Row justify={'space-between'} align={'middle'}>
                          <FormListMovingArrows move={move} name={name} />
                          <DeleteFormListBlock remove={remove} name={name} />
                        </Row>
                      </Form.Item>
                      <Form.Item name={[name, 'image']} valuePropName={'src'}>
                        <ImageWithFallback height={50} />
                      </Form.Item>
                      <FormFileManagerSelect
                        fieldKey={fieldKey}
                        label={'Изображение'}
                        formInstance={form}
                        pathFieldName={['stocks', name, 'image']}
                        initialPath={getStaticPageImagePath(data?.data?.stocks[name].image)}
                      />

                      <Form.Item
                        fieldKey={fieldKey}
                        label={'Название склада'}
                        name={[name, 'label']}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        fieldKey={fieldKey}
                        label={'Дополнительный текст'}
                        name={[name, 'description']}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        fieldKey={fieldKey}
                        label={'Адрес склада'}
                        name={[name, 'address']}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        fieldKey={fieldKey}
                        label={'Ссылка на яндекс карту'}
                        name={[name, 'url']}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </React.Fragment>
                  ))}

                  <Form.Item>
                    <Button
                      block
                      type={'dashed'}
                      icon={<PlusCircleOutlined />}
                      onClick={() => add()}
                    >
                      Добавить склад
                    </Button>
                  </Form.Item>
                </React.Fragment>
              )}
            </Form.List>
          </Col>
          <Col xs={24} xl={12}>
            <Typography.Title level={4}>Доставка</Typography.Title>
            <Form.Item
              label={'Минимальная сумма заказа'}
              name={'delivery_min_price'}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <FormDatePicker label={'Срок действия тарифов - до'} name={'tariff_validity'} />

            <Form.Item label={'Тарифы'}>
              <Form.List name={'tariffs'}>
                {(fields, { add, remove, move }) => (
                  <React.Fragment>
                    {fields.map(({ name, key, fieldKey }) => (
                      <React.Fragment key={key}>
                        <Form.Item>
                          <Row justify={'space-between'} align={'middle'}>
                            <FormListMovingArrows move={move} name={name} />
                            <DeleteFormListBlock remove={remove} name={name} />
                          </Row>
                        </Form.Item>
                        <Form.Item
                          fieldKey={fieldKey}
                          label={'Сумма заказа'}
                          name={[name, 'order_price']}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          fieldKey={fieldKey}
                          label={'Стоимость доставки в пределах МКАД'}
                          name={[name, 'within_mkad']}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          fieldKey={fieldKey}
                          label={'Стоимость доставки за пределы МКАД'}
                          name={[name, 'outside_of_mkad']}
                        >
                          <Input />
                        </Form.Item>
                      </React.Fragment>
                    ))}
                    <Button
                      type={'dashed'}
                      block
                      onClick={() => add()}
                      icon={<PlusCircleOutlined />}
                    >
                      Добавить тариф
                    </Button>
                  </React.Fragment>
                )}
              </Form.List>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Typography.Title level={4}>Оплата</Typography.Title>
            <Form.Item label={'Текстовая подсказка'} name={'payment_text'}>
              <TextEditor />
            </Form.Item>
            <FeedbackFormRadio />
          </Col>
          <Col xs={24}>
            <FormButtons isLoading={update.isLoading} />
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default DeliveryPaymentPage
