import makeRequest from '@api/makeRequest'
import { IStaticContentUpdateData, StaticContentType } from '@app/interfaces/static-content'

const url = '/api/static-content'

class StaticContent {
  getLayout(pageType: StaticContentType) {
    return makeRequest({ url: url + '/' + pageType })
  }

  updateLayout({ pageType, data }: IStaticContentUpdateData) {
    return makeRequest({ url: url + '/' + pageType, method: 'PUT', data: { [pageType]: data } })
  }
}

export default new StaticContent()
