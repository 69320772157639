import React from 'react'
import { Button, Col, Form, Input, PageHeader, Row, Skeleton, Typography } from 'antd'
import {
  IContactsPage,
  IStaticContentResponse,
  StaticContentType,
} from '@app/interfaces/static-content'
import { useStaticContent } from '@app/hooks/query/static-content'
import {
  DeleteFormListBlock,
  ErrorBoundary,
  FeedbackFormRadio,
  FormButtons,
  FormFileManagerSelect,
  FormListMovingArrows,
  FormSeoInputs,
  ImageWithFallback,
  SiteLink,
} from '@app/shared'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useUpdateStaticContent } from '@app/hooks/useUpdateStaticContent'
import { getStaticPageImagePath } from '@app/utils/getStaticPageImagePath'

interface props {}

const ContactsPage: React.FC<props> = () => {
  const [form] = Form.useForm()
  const pageType: StaticContentType = 'about_contacts'
  const { isLoading, isError, data } =
    useStaticContent<IStaticContentResponse<IContactsPage>>(pageType)

  const update = useUpdateStaticContent(pageType)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const onFinish = (data: IContactsPage) => update.mutateAsync({ pageType, data })

  return (
    <React.Fragment>
      <PageHeader
        title={'Контакты'}
        extra={[<SiteLink key={1} entityType={'o-kompanii/kontakty'} />]}
      />
      <Form form={form} onFinish={onFinish} layout={'vertical'} initialValues={data?.data}>
        <Typography.Title level={4}>Заголовок H1</Typography.Title>
        <Form.Item name={['page', 'h1']}>
          <Input placeholder={'Введите заголовок H1'} />
        </Form.Item>
        <Typography.Title level={4}>SEO</Typography.Title>
        <FormSeoInputs isPageTitleRequired={false} />
        <Row gutter={[32, 32]}>
          <Col xs={24}>
            <Typography.Title level={4}>Наша команда</Typography.Title>
            <Form.List name={'team'}>
              {(fields, { add, remove, move }) => (
                <Row gutter={[16, 16]}>
                  {fields.map(({ name, fieldKey, key }) => (
                    <Col key={key} xs={24} xl={6}>
                      <Form.Item>
                        <Row align={'middle'} justify={'space-between'}>
                          <FormListMovingArrows move={move} name={name} />
                          <DeleteFormListBlock remove={remove} name={name} />
                        </Row>
                      </Form.Item>
                      <Form.Item name={[name, 'image']} valuePropName={'src'}>
                        <ImageWithFallback height={50} />
                      </Form.Item>
                      <FormFileManagerSelect
                        fieldKey={fieldKey}
                        formInstance={form}
                        pathFieldName={['team', name, 'image']}
                        label={'Фотография'}
                        initialPath={getStaticPageImagePath(data?.data?.team[name].image)}
                      />
                      <Form.Item
                        fieldKey={fieldKey}
                        name={[name, 'name']}
                        label={'Имя'}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        fieldKey={fieldKey}
                        name={[name, 'job_title']}
                        label={'Должность'}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  ))}
                  <Col xs={24}>
                    <Form.Item>
                      <Button
                        type={'dashed'}
                        block
                        icon={<PlusCircleOutlined />}
                        onClick={() => add()}
                      >
                        Добавить блок
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form.List>
          </Col>
          <Col xs={24}>
            <FeedbackFormRadio />
          </Col>
          <Col xs={24}>
            <FormButtons isLoading={update.isLoading} />
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default ContactsPage
