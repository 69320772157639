import { useQuery, UseQueryOptions } from 'react-query'
import { ProductsService } from '@app/api'
import { AxiosResponse } from 'axios'
import { idType } from '@app/interfaces'
import { IProduct, IProductResponse, IProductVariantsResponse } from '@app/interfaces/product'

export const PRODUCTS_KEY = 'products_key'
export const PRODUCT_VARIANTS = 'product_variants_key'

export function useProducts(options?: UseQueryOptions<IProductResponse, Error>) {
  return useQuery<IProductResponse, Error>(
    PRODUCTS_KEY,
    () =>
      ProductsService.getAll().then((response: AxiosResponse<IProductResponse>) => response.data),
    { ...options },
  )
}

export function useProduct(id: idType, options?: UseQueryOptions<IProduct, Error>) {
  return useQuery<IProduct, Error>(
    [PRODUCTS_KEY, id],
    () => ProductsService.getById(id).then((response: AxiosResponse<IProduct>) => response.data),
    { ...options },
  )
}

export function useProductVariants(
  id: idType,
  options?: UseQueryOptions<IProductVariantsResponse, Error>,
) {
  return useQuery<IProductVariantsResponse, Error>(
    [PRODUCT_VARIANTS, id],
    () =>
      ProductsService.variants(id).then(
        (response: AxiosResponse<IProductVariantsResponse>) => response.data,
      ),
    {
      ...options,
    },
  )
}
