import React from 'react'
import { PageInfo } from '@app/shared'
import { Alert, Col, PageHeader, Row, Typography } from 'antd'
import { SyncBlock } from '@app/pages/sync-page/components'
import { SyncService } from '@app/api'

interface props {}

const SyncPage: React.FC<props> = () => {
  const title = 'Синхронизация'

  return (
    <React.Fragment>
      <PageInfo title={title} />
      <PageHeader title={title} />
      <Row gutter={[32, 32]}>
        <Col xs={24}>
          <Alert
            showIcon={true}
            type={'warning'}
            message={
              <Typography.Text>
                Внимание! Данная процедура займет некоторое время (от нескольких секунд до
                нескольких минут). На момент синхронизации, пожалуйста, не покидайте данную страницу
                и не закрывайте вкладку.
              </Typography.Text>
            }
          />
        </Col>
        <SyncBlock
          title={'All'}
          description={'Запускает синхронизацию всех таблиц'}
          endpoint={SyncService.all}
        />
        <SyncBlock
          title={'Адреса'}
          description={'Запускает синхронизацию таблицы addresses'}
          endpoint={SyncService.addresses}
        />
        <SyncBlock
          title={'Атрибуты'}
          description={'Command description'}
          endpoint={SyncService.attributes}
        />
        <SyncBlock
          title={'Бренды'}
          description={'Запускает синхронизацию таблицы brands'}
          endpoint={SyncService.brands}
        />
        <SyncBlock
          title={'Категории'}
          description={'Запускает синхронизацию таблицы categories'}
          endpoint={SyncService.categories}
        />
        <SyncBlock
          title={'Типы документов'}
          description={'Command description'}
          endpoint={SyncService.documentTypes}
        />
        <SyncBlock
          title={'Документы'}
          description={'Запускает синхронизацию таблицы documents'}
          endpoint={SyncService.documents}
        />
        <SyncBlock
          title={'Invoice параметры'}
          description={'Запускает синхронизацию таблицы brands'}
          endpoint={SyncService.invoiceParameters}
        />
        <SyncBlock
          title={'Invoice статусы'}
          description={'Запускает синхронизацию таблицы invoice_statuses'}
          endpoint={SyncService.invoiceStatuses}
        />
        <SyncBlock
          title={'Роли менеджера'}
          description={'Запускает синхронизацию таблицы manager-roles'}
          endpoint={SyncService.managerRoles}
        />
        <SyncBlock
          title={'Статусы заказа'}
          description={'Запускает синхронизацию таблицы order_statuses'}
          endpoint={SyncService.orderStatuses}
        />
        <SyncBlock
          title={'Организации'}
          description={'Запускает синхронизацию таблиц managers, holdings, organizations'}
          endpoint={SyncService.organizations}
        />
        <SyncBlock
          title={'Партнеры'}
          description={'Запускает синхронизацию таблицы partners и partner_link'}
          endpoint={SyncService.partners}
        />
        <SyncBlock
          title={'Цены'}
          description={'Запускает синхронизацию таблицы prices и price_types'}
          endpoint={SyncService.prices}
        />
        <SyncBlock
          title={'Продукты'}
          description={'Запускает синхронизацию таблицы products'}
          endpoint={SyncService.products}
        />
        <SyncBlock
          title={'Промотеги'}
          description={'Запускает синхронизацию таблицы promotags'}
          endpoint={SyncService.products}
        />
        <SyncBlock
          title={'Наличие'}
          description={'Запускает синхронизацию таблицы stocks'}
          endpoint={SyncService.stocks}
        />
        <SyncBlock
          title={'Заказы'}
          description={'Запускает синхронизацию таблицы orders'}
          endpoint={SyncService.syncOrders}
        />
        <SyncBlock
          title={'Единицы измерения'}
          description={'Запускает синхронизацию таблицы units (единица измерения)'}
          endpoint={SyncService.units}
        />
        <SyncBlock
          title={'Пользователи'}
          description={'Запускает синхронизацию таблицы users'}
          endpoint={SyncService.users}
        />
        <SyncBlock
          title={'Сопутствующие товары'}
          description={'Запускает синхронизацию цветов, размеров, сопутствующих товаров'}
          endpoint={SyncService.variants}
        />
      </Row>
    </React.Fragment>
  )
}

export default SyncPage
