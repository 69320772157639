import React, { useState } from 'react'
import { Alert, Anchor, Button, Card, Col, Drawer, Image, Row, Typography } from 'antd'
import { FileImageOutlined } from '@ant-design/icons'
import carousel from '@assets/images/image-references/carousel.png'
import grid from '@assets/images/image-references/grid.jpg'
import productBlock from '@assets/images/image-references/product-block.jpg'
import homeMainBanner from '@assets/images/image-references/home-main-banner.jpg'
import promotions from '@assets/images/image-references/promotions.jpg'
import articlesNews from '@assets/images/image-references/articles-news.jpg'
import categorySlider from '@assets/images/image-references/category-slider.jpg'
import categorySideBanner from '@assets/images/image-references/category-side-banner.jpg'
import subcategories from '@assets/images/image-references/subcategories.jpg'
import productImage from '@assets/images/image-references/product-image.jpg'
import advantages from '@assets/images/image-references/advantages.jpg'
import article from '@assets/images/image-references/article.png'
import articlesList from '@assets/images/image-references/articles-list.jpg'

import styles from './image-references.module.scss'

const { Link } = Anchor

interface props {}

const ImageReferences: React.FC<props> = () => {
  const [visible, setVisible] = useState(false)
  const handleChangeVisible = () => setVisible(!visible)

  const links = [
    { href: '#home-slider', title: 'Слайдер на главной странице' },
    { href: '#categories-grid', title: 'Сетка категорий' },
    { href: '#product-block', title: 'Блок товара' },
    { href: '#home-main-banner', title: 'Баннер главной страницы' },
    { href: '#promotions', title: 'Промоакции' },
    { href: '#articles-news', title: 'Статьи и новости' },
    { href: '#category-slider', title: 'Слайдер страницы категории' },
    { href: '#category-side-banner', title: 'Баннеры с боку страницы категории' },
    { href: '#subcategories', title: 'Список подкатегорий' },
    { href: '#product-image', title: 'Изображение продукта' },
    { href: '#advantages', title: 'Преимущества' },
    { href: '#article-main-image', title: 'Изображение статьи' },
    { href: '#articles-list', title: 'Список статей и новостей' },
  ]

  return (
    <React.Fragment>
      <Button
        type={'primary'}
        className={styles.button}
        onClick={handleChangeVisible}
        icon={<FileImageOutlined />}
      >
        Документация по изображениям
      </Button>
      <Drawer
        height={'100%'}
        placement={'top'}
        title={'Документация по изображениям'}
        onClose={handleChangeVisible}
        footer={false}
        visible={visible}
        destroyOnClose={true}
      >
        <Row gutter={[48, 48]}>
          <Col xs={4}>
            <Anchor targetOffset={50} affix={true} offsetTop={75}>
              {links.map((link, index) => (
                <Link href={link.href} title={link.title} key={index} />
              ))}
            </Anchor>
          </Col>
          <Col xs={20}>
            <Row gutter={[24, 24]}>
              <Col xs={24}>
                <Alert
                  type={'warning'}
                  message={'Ширина(px)/Высота(px) - Соотношение по ширине/по высоте'}
                  showIcon
                />
              </Col>
              <Col id={'home-slider'} xs={24}>
                <Card title={'Слайдер на главной странице'}>
                  <Row gutter={[32, 32]}>
                    <Col>
                      <Image src={carousel} height={150} />
                    </Col>
                    <Col>
                      <Typography.Title level={5}>Основной блок</Typography.Title>
                      <ul>
                        <li>Мобильная версия: 640px/551px; 1/1.15</li>
                        <li>Планшетная версия: 926px/686px; 1/1.35</li>
                        <li>ПК версия (1366x768): 841px/520px; 1/1.6</li>
                        <li>ПК fullHD (1920:1080): 768px/713px; 1/1.07</li>
                      </ul>
                    </Col>
                    <Col>
                      <Typography.Title level={5}>Фиксированный блок</Typography.Title>
                      <ul>
                        <li>Мобильная версия: 140px/235px; 0.6/1</li>
                        <li>Планшетная версия: 886px/280px; 1/3.16</li>
                        <li>ПК версия (1366x768): 420px/520px; 0.8/1</li>
                        <li>ПК fullHD (1920:1080): 512px/569px; 0.9/1</li>
                      </ul>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col id={'categories-grid'} xs={24}>
                <Card title={'Сетка категорий'}>
                  <Row gutter={[32, 32]}>
                    <Col>
                      <Image src={grid} height={150} />
                    </Col>
                    <Col>
                      <ul>
                        <li>Мобильная версия: 320px/335px; 1/1</li>
                        <li>Планшетная версия: 325px/335px; 1/1</li>
                        <li>ПК версия (1366x768): 431px/341px; 1/1</li>
                        <li>ПК fullHD (1920:1080): 580px/499px; 1/1</li>
                      </ul>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col id={'product-block'} xs={24}>
                <Card title={'Блок товара'}>
                  <Row gutter={[32, 32]}>
                    <Col>
                      <Image src={productBlock} height={150} />
                    </Col>
                    <Col>
                      <ul>
                        <li>Мобильная версия: 226px/224px; 1/1</li>
                        <li>Планшетная версия: 316px/307px; 1/1</li>
                        <li>ПК версия (1366x768): 400px/409px; 1/1</li>
                        <li>ПК fullHD (1920:1080): 584px/598px; 1/1</li>
                      </ul>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col id={'home-main-banner'} xs={24}>
                <Card title={'Баннер главной страницы'}>
                  <Row gutter={[32, 32]}>
                    <Col>
                      <Image src={homeMainBanner} height={150} />
                    </Col>
                    <Col>
                      <ul>
                        <li>Мобильная версия: 320px/526px; 1/1.3</li>
                        <li>Планшетная версия: 663px/320px; 2/1</li>
                        <li>ПК версия (1366x768): 1261px/456px; 2.8/1</li>
                        <li>ПК fullHD (1920:1080): 1812px/470px; 3.8/1</li>
                      </ul>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col id={'promotions'} xs={24}>
                <Card title={'Промоакции'}>
                  <Row gutter={[32, 32]}>
                    <Col>
                      <Image src={promotions} height={150} />
                    </Col>
                    <Col>
                      <ul>
                        <li>Мобильная версия: 320px/370px; 1/1.2</li>
                        <li> Планшетная версия: 316px/325px; 1/1.2</li>
                        <li>ПК версия (1366x768): 400px/473px; 1/1.2</li>
                        <li>ПК fullHD (1920:1080): 584px/587px; 1/1.2</li>
                      </ul>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col id={'articles-news'} xs={24}>
                <Card title={'Статьи и новости'}>
                  <Row gutter={[32, 32]}>
                    <Col>
                      <Image src={articlesNews} height={150} />
                    </Col>
                    <Col>
                      <ul>
                        <li>Мобильная версия: 209px/146px; 1/1.25</li>
                        <li>Планшетная версия: 321px/240px; 1/1.25</li>
                        <li>ПК версия (1366x768): 407px/285px; 1/1.25</li>
                        <li>ПК fullHD (1920:1080): 581px/399px; 1/1.25</li>
                      </ul>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col id={'category-slider'} xs={24}>
                <Card title={'Слайдер категории'}>
                  <Row gutter={[32, 32]}>
                    <Col>
                      <Image src={categorySlider} height={200} />
                    </Col>
                    <Col>
                      <ul>
                        <li>Мобильная версия: 320px/487px; 1/1.5</li>
                        <li>Планшетная версия: 663px/354px; 1.9/1</li>
                        <li>ПК версия (1366x768): 868px/479px; 1.8/1</li>
                        <li>ПК fullHD (1920:1080): 1234px/704px; 1.8/1</li>
                      </ul>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col id={'category-side-banner'} xs={24}>
                <Card title={'Баннеры с боку страницы категории'}>
                  <Row gutter={[32, 32]}>
                    <Col>
                      <Image src={categorySideBanner} height={200} />
                    </Col>
                    <Col>
                      <ul>
                        <li>Мобильная версия: 320px/197px; 1/1.6</li>
                        <li>Планшетная версия: 324px/198px; 1/1.6</li>
                        <li>ПК версия (1366x768): 383px/274px; 1/1.6</li>
                        <li>ПК fullHD (1920:1080): 567px/346px; 1/1.6</li>
                      </ul>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col id={'subcategories'} xs={24}>
                <Card title={'Список подкатегорий'}>
                  <Row gutter={[32, 32]}>
                    <Col>
                      <Image src={subcategories} height={200} />
                    </Col>
                    <Col>
                      <ul>
                        <li>Все экраны: 130px/100px; 1.25/1</li>
                      </ul>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col id={'product-image'} xs={24}>
                <Card title={'Изображение продукта'}>
                  <Row gutter={[32, 32]}>
                    <Col>
                      <Image src={productImage} height={250} />
                    </Col>
                    <Col>
                      <ul>
                        <li>Мобильная версия: 230px/350px; 1.5/1</li>
                        <li>Планшетная версия: 331px/450px; 1.4/1</li>
                        <li>ПК версия (1366x768): 631px/450px; 1/1.4</li>
                        <li>ПК fullHD (1920:1080): 661px/550px; 1/1.2</li>
                      </ul>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col id={'advantages'} xs={24}>
                <Card title={'Преимущества'}>
                  <Row gutter={[32, 32]}>
                    <Col>
                      <Image src={advantages} height={150} />
                    </Col>
                    <Col>
                      <ul>
                        <li>Мобильная версия: 320px/217px; 1.5/1</li>
                        <li>Планшетная версия: 703px/476px; 1.5/1</li>
                        <li>ПК версия (1366x768): 650px/500px; 1.5/1</li>
                        <li>ПК fullHD (1920:1080): 956px/648px; 1.5/1</li>
                      </ul>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col id={'article-main-image'} xs={24}>
                <Card title={'Изображение статьи'}>
                  <Row gutter={[32, 32]}>
                    <Col>
                      <Image src={article} height={250} />
                    </Col>
                    <Col>
                      <ul>
                        <li>Мобильная версия: 320px/192px; 1.6/1</li>
                        <li>Планшетная версия: 703px/460px; 1.6/1</li>
                        <li>ПК версия (1366x768): 740px/450px; 1.6/1</li>
                        <li>ПК fullHD (1920:1080): 1288px/784px; 1.6/1</li>
                      </ul>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col id={'articles-list'} xs={24}>
                <Card title={'Список статей и новостей'}>
                  <Row gutter={[32, 32]}>
                    <Col>
                      <Image src={articlesList} height={250} />
                    </Col>
                    <Col>
                      <ul>
                        <li>Мобильная версия: 236px/135px; 1.75/1</li>
                        <li>Планшетная версия: 278px/135px; 2.05/1</li>
                        <li>ПК версия (1366x768): 238px/135px; 1.77/1</li>
                        <li>ПК fullHD (1920:1080): 267px/154px; 1.74/1</li>
                      </ul>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </React.Fragment>
  )
}

export default ImageReferences
