import React from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { ElasticButton } from '@app/shared'
import { ElasticService } from '@app/api'

interface props {}

const ImportProducts: React.FC<props> = () => {
  return (
    <ElasticButton
      icon={<DownloadOutlined />}
      mutateFunction={ElasticService.refreshProducts}
      successText={'Товары успешно импортированы'}
      errorText={'Ошибка синхронизации! Попробуйте, пожалуйста, снова'}
    >
      Импорт товаров
    </ElasticButton>
  )
}

export default ImportProducts
