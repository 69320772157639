import React from 'react'
import { CATEGORIES_KEY, useCategory } from '@app/hooks/query/categories'
import { Link, useParams } from 'react-router-dom'
import {
  Button,
  Card,
  Col,
  Divider,
  List,
  message,
  Row,
  Skeleton,
  Space,
  Tabs,
  Typography,
} from 'antd'
import {
  CategoryColor,
  CategoryLink,
  CategoryList,
  ErrorBoundary,
  ImagesGrid,
  ImageWithFallback,
  PageInfo,
  PromotagsList,
  SecondaryText,
  SeoCard,
  SiteLink,
  SpaceWithDivider,
  StatusTag,
  Subtitle,
} from '@app/shared'
import { privateRoutes } from '@app/routes'
import {
  CategoryBlock,
  CategoryBlockModal,
  CategoryTextField,
  CategoryVideoBlock,
  FilterAttributes,
} from '@app/pages/category-page/components'
import { VideoCameraOutlined } from '@ant-design/icons'
import { useMutation } from 'react-query'
import { VideoBlockService } from '@app/api'
import { queryClient } from '@app/index'

const { Title } = Typography

interface props {}

const CategoryPage: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { data, isLoading, isError } = useCategory(id)

  const video = useMutation(VideoBlockService.create, {
    onSuccess: () => {
      message.success('Блок успешно создан')
      queryClient.invalidateQueries([CATEGORIES_KEY, id])
    },
    onError: () => {
      message.error('Произошла ошибка! Обратитесь к системному администратору')
    },
  })

  const createVideoHandler = () => video.mutateAsync({ category_id: id, title: 'Видео' })

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const {
    title,
    slug,
    active,
    image_path,
    images_links,
    color,
    children,
    parent,
    banners,
    promotags,
    type,
    page,
    product_block,
    video_block,
    text_field,
  } = data!

  return (
    <React.Fragment>
      <PageInfo title={title} />
      <Row gutter={[16, 16]}>
        <Col xs={24} xl={18}>
          <Card
            title={'Общая информация'}
            extra={[
              <Space key={1}>
                <StatusTag status={active} key={1} />
                <SiteLink slug={slug} entityType={'category'} />
              </Space>,
            ]}
          >
            <ImagesGrid imagesLinks={images_links} image={image_path} />
            <Title level={2}>{title}</Title>
            <SpaceWithDivider>
              <SecondaryText>ID: {id}</SecondaryText>
              <SecondaryText>Slug: {slug}</SecondaryText>
              <CategoryColor color={color} />
              <SecondaryText>
                Родительская категория:{' '}
                {parent ? <CategoryLink title={parent.title} id={parent.id} /> : 'Отсутствует'}
              </SecondaryText>
              <SecondaryText>Тип категории: {type.description}</SecondaryText>
            </SpaceWithDivider>
            <Divider />
            <Subtitle>Баннеры</Subtitle>
            <List
              grid={{ gutter: 16, column: 3 }}
              dataSource={banners}
              renderItem={({ id, link_title, active, desktop, mobile, tablet }) => (
                <List.Item>
                  <Card
                    title={
                      <Link to={privateRoutes.banners.path + '/details/' + id}>{link_title}</Link>
                    }
                    extra={[<StatusTag status={active} key={1} />]}
                  >
                    <Tabs>
                      <Tabs.TabPane tab={'Смартфон'} key={mobile}>
                        <ImageWithFallback src={mobile} preview />
                      </Tabs.TabPane>
                      <Tabs.TabPane tab={'Планшет'} key={tablet}>
                        <ImageWithFallback src={tablet} preview />
                      </Tabs.TabPane>
                      <Tabs.TabPane tab={'ПК'} key={desktop}>
                        <ImageWithFallback src={desktop} preview />
                      </Tabs.TabPane>
                    </Tabs>
                  </Card>
                </List.Item>
              )}
            />
            <Divider />
            <Subtitle>Промотеги</Subtitle>
            <PromotagsList promotags={promotags} />
            <Divider />
          </Card>
        </Col>
        <Col xs={24} xl={6}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <SeoCard title={page.title} description={page.description} keywords={page.keywords} />
            </Col>
            <Col xs={24}>
              <CategoryList categories={children} title={'Подкатегории'} />
            </Col>
          </Row>
        </Col>
        <Divider />
        <Col xs={24}>
          <FilterAttributes />
        </Col>
        <Divider />
        <Col xs={24}>
          {product_block ? <CategoryBlock id={product_block.id} /> : <CategoryBlockModal />}
        </Col>
        <Divider />
        <Col xs={24}>
          <CategoryTextField textField={text_field} />
        </Col>
        <Divider />
        <Col xs={24}>
          {video_block ? (
            <CategoryVideoBlock id={video_block.id} />
          ) : (
            <Button
              type={'dashed'}
              block
              icon={<VideoCameraOutlined />}
              onClick={createVideoHandler}
              loading={video.isLoading}
              disabled={video.isLoading}
            >
              Создать блок с видео для текущей категории
            </Button>
          )}
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CategoryPage
