import React, { useState } from 'react'
import { Button, message } from 'antd'
import { useMutation } from 'react-query'
import { AxiosPromise } from 'axios'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import styles from './elastic-button.module.scss'

interface props {
  icon: React.ReactNode
  mutateFunction: () => AxiosPromise<any>
  successText: string
  errorText: string
}

const ElasticButton: React.FC<props> = ({
  children,
  mutateFunction,
  icon,
  successText,
  errorText,
}) => {
  const [status, setStatus] = useState<'success' | 'error' | null>(null)
  const { isLoading, mutateAsync } = useMutation(mutateFunction, {
    onSuccess: () => {
      message.success(successText)
      setStatus('success')
    },
    onError: () => {
      message.error(errorText)
      setStatus('error')
    },
  })

  const onClick = () => mutateAsync()

  const renderIcon = () => {
    if (status === 'success') return <CheckCircleOutlined />
    if (status === 'error') return <CloseCircleOutlined />
    return icon
  }

  return (
    <Button
      type={status ? 'primary' : 'default'}
      onClick={onClick}
      loading={isLoading}
      disabled={isLoading}
      danger={status === 'error'}
      className={status === 'success' ? styles.success : ''}
    >
      <span className="anticon">{renderIcon()}</span>
      {children}
    </Button>
  )
}

export default ElasticButton
