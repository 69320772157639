import React from 'react'
import { useRobotsFile } from '@app/hooks/query/robots'
import { Skeleton } from 'antd'
import { ErrorBoundary, PageInfo } from '@app/shared'
import { RobotsFileEditor } from '@app/pages/robots-file-page/components'

interface props {}

const RobotsFilePage: React.FC<props> = () => {
  const { data, isLoading, isError } = useRobotsFile()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { content } = data!

  return (
    <React.Fragment>
      <PageInfo title={'Robots.txt'} />
      <RobotsFileEditor content={content} />
    </React.Fragment>
  )
}

export default RobotsFilePage
