import React from 'react'
import { ExportFeedbackForms, ExportMailingList, SpaceWithDivider } from '@app/shared'

interface props {}

const ExportsPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <SpaceWithDivider>
        <ExportMailingList />
        <ExportFeedbackForms />
      </SpaceWithDivider>
    </React.Fragment>
  )
}

export default ExportsPage
