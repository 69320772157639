import makeRequest from '@api/makeRequest'

const url = '/api/export'

class ExportService {
  subscribers() {
    return makeRequest({
      url: url + '/subscribers',
      responseType: 'blob',
    })
  }
}

export default new ExportService()
