import React from 'react'
import { AxiosPromise } from 'axios'
import { useMutation } from 'react-query'
import { Button, Card, Col, message, Row, Typography } from 'antd'
import { SyncOutlined } from '@ant-design/icons'

interface props {
  title: string
  description: string
  endpoint: () => AxiosPromise
}

const SyncBlock: React.FC<props> = ({ title, description, endpoint }) => {
  const { isLoading, mutateAsync } = useMutation(endpoint, {
    onSuccess: () => {
      message.success('Синхронизация прошла успешно')
    },
    onError: () => {
      message.error('Произошла ошибка! Попробуйте, пожалуйста, позже')
    },
  })

  const handleClick = () => mutateAsync()

  return (
    <Col xs={24}>
      <Card>
        <Row gutter={[24, 24]} align={'middle'}>
          <Col xs={24} xl={6}>
            <Button
              type={'primary'}
              danger={isLoading}
              ghost
              onClick={handleClick}
              icon={<SyncOutlined />}
              loading={isLoading}
              block
            >
              {title}
            </Button>
          </Col>
          <Col xs={24} xl={18}>
            <Typography.Text strong>{description}</Typography.Text>
          </Col>
        </Row>
      </Card>
    </Col>
  )
}

export default SyncBlock
