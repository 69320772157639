import makeRequest from '@api/makeRequest'
import queryString from 'query-string'
import { history } from '@api/history'
import { idType } from '@app/interfaces'
import { CategoryPageUpdateData } from '@app/interfaces/category'

const url = '/api/categories'

class CategoriesService {
  getAll() {
    return makeRequest({
      url,
      params: queryString.parse(history.location.search),
    })
  }

  getById(id: idType) {
    return makeRequest({
      url: url + '/' + id,
    })
  }

  getFilterCategories() {
    return makeRequest({
      url: url + '/list',
    })
  }

  updatePageContent(data: CategoryPageUpdateData) {
    return makeRequest({
      url: url + '/' + data.id + '/updatePageContent',
      method: 'PUT',
      data: {
        title: data.title,
        content: data.content,
      },
    })
  }
}

export default new CategoriesService()
