import React from 'react'
import { AddLink, ErrorBoundary, PageInfo } from '@app/shared'
import { PageHeader, Skeleton } from 'antd'
import { ProductSelectionTable } from '@app/pages/product-selection-page/components'
import { useSelections } from '@app/hooks/query/selections'

interface props {}

const ProductSelectionPage: React.FC<props> = () => {
  const { isLoading, isError, data } = useSelections()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { data: selections, ...pagination } = data!

  return (
    <React.Fragment>
      <PageInfo title={'Подборка товаров'} />
      <PageHeader
        title={'Подборка товаров'}
        extra={[<AddLink key={'1'} route={'add_product_selection'} />]}
      />
      <ProductSelectionTable data={selections} pagination={pagination} />
    </React.Fragment>
  )
}

export default ProductSelectionPage
