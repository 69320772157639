import React, { useEffect, useState } from 'react'
import { Button, Input, message, PageHeader, Typography } from 'antd'
import styles from './robots-file-editor.module.scss'
import { useMutation } from 'react-query'
import { RobotsService } from '@app/api'
import { LoadingOutlined, SaveOutlined } from '@ant-design/icons'
import { AxiosResponse } from 'axios'
import { queryClient } from '@app/index'
import { ROBOTS_FILE_KEY } from '@app/hooks/query/robots'

interface props {
  content: string
}

const RobotsFileEditor: React.FC<props> = ({ content }) => {
  const [value, setValue] = useState<string>(content)
  const handleValueChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value)

  useEffect(() => {
    const handleKeyPress = (ev: KeyboardEvent) => {
      if (ev.code === 'KeyS' && ev.ctrlKey) {
        ev.preventDefault()
        handleSubmit()
      }
    }

    window.addEventListener('keypress', handleKeyPress)

    return () => {
      window.removeEventListener('keypress', handleKeyPress)
    }
  }, [])

  const { isLoading, mutateAsync } = useMutation(RobotsService.setRobots, {
    onSuccess: async (e: AxiosResponse<{ message: string }>) => {
      await queryClient.invalidateQueries(ROBOTS_FILE_KEY)
      message.success(e.data.message)
    },
    onError: () => {
      message.error('Ошибка сохранения! Попробуйте позже.')
    },
  })

  const handleSubmit = () => mutateAsync(value)

  return (
    <>
      <PageHeader
        title={'Robots.txt'}
        subTitle={
          <Typography.Text type={'secondary'}>
            Нажмите сочетание <Typography.Text code>CTRL + S</Typography.Text> для сохранения файла.{' '}
            {isLoading && <LoadingOutlined />}
          </Typography.Text>
        }
        extra={[
          <Button
            key={'1'}
            type={'primary'}
            icon={<SaveOutlined />}
            disabled={isLoading}
            loading={isLoading}
            onClick={handleSubmit}
          >
            Сохранить
          </Button>,
        ]}
      />
      <Input.TextArea className={styles.textArea} onChange={handleValueChange} value={value} />
    </>
  )
}

export default RobotsFileEditor
