import makeRequest from '@api/makeRequest'
import queryString from 'query-string'
import { history } from '@api/history'
import { idType } from '@app/interfaces'
import { IProductVariantData } from '@app/interfaces/product'

const url = '/api/products'

class ProductsService {
  getAll() {
    return makeRequest({
      url,
      params: queryString.parse(history.location.search),
    })
  }

  getById(id: idType) {
    return makeRequest({
      url: url + '/' + id,
    })
  }

  search(q: string) {
    return makeRequest({
      url: url + '/search',
      params: { q },
    })
  }

  variants(productId: idType) {
    return makeRequest({
      url: url + '/' + productId + '/variants',
    })
  }

  editVariants({ productId, data }: { productId: idType; data: IProductVariantData[] }) {
    return makeRequest({
      url: url + '/' + productId + '/variants',
      method: 'PATCH',
      data: {
        product_variants: data,
      },
    })
  }
}

export default new ProductsService()
