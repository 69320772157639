import React from 'react'
import { IAdvertisement } from '@app/interfaces/advertisements'
import { Table } from 'antd'
import { DeleteModal, EditLink, ImageWithFallback, Pagination, SpaceWithDivider } from '@app/shared'
import { ADVERTISEMENTS_KEY } from '@app/hooks/query/advertisements'
import { ColumnsType } from 'antd/lib/table'
import { IAdvertisementType } from '@app/interfaces/ads-types'
import { AdvertisementsService } from '@app/api'
import { IPagination } from '@app/interfaces'

interface props {
  data: IAdvertisement[]
  pagination: IPagination
}

const AdvertisementsTable: React.FC<props> = ({ data, pagination }) => {
  const tableFooter = () => (
    <Pagination pagination={pagination} fetchingQueryKey={ADVERTISEMENTS_KEY} />
  )

  const columns: ColumnsType<IAdvertisement> = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Заголовок',
      dataIndex: 'title',
      render: (text: string) => <>{text ? text : 'Отсутствует'}</>,
    },
    {
      title: 'Изображение',
      dataIndex: 'image_path',
      render: (path: string) => <ImageWithFallback src={path} width={100} />,
    },
    {
      title: 'Категория рекламных материалов',
      dataIndex: 'type',
      render: (type: IAdvertisementType) => <>{type.title}</>,
    },
    {
      title: 'Порядковый номер',
      dataIndex: 'order',
      render: (text: number) => <>{text ? text : '-'}</>,
    },
    {
      title: 'Для взрослых',
      dataIndex: 'adult',
      render: (adult: boolean) => <>{adult ? 'Да' : 'Нет'}</>,
    },
    {
      title: 'Прикрепленный файл',
      dataIndex: 'file',
      render: (text?: string) =>
        text ? (
          <a href={text} rel={'noopener noreferrer'} target={'_blank'}>
            Открыть
          </a>
        ) : (
          'Отсутствует'
        ),
    },
    {
      title: 'Действия',
      key: 'actions',
      align: 'right',
      render: (record: IAdvertisement) => (
        <SpaceWithDivider>
          <EditLink id={record.id} route={'advertisements'} />
          <DeleteModal
            id={record.id}
            deleteFunction={AdvertisementsService.delete}
            invalidateQueryKey={ADVERTISEMENTS_KEY}
          />
        </SpaceWithDivider>
      ),
    },
  ]

  return (
    <Table
      footer={tableFooter}
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={{ x: true }}
      rowKey={'id'}
      bordered
    />
  )
}

export default AdvertisementsTable
