import React from 'react'
import { AddLink, ErrorBoundary, PageInfo, SiteLink } from '@app/shared'
import { PageHeader, Skeleton, Tabs } from 'antd'
import { useArticles } from '@app/hooks/query/articles'
import { ArticlesPageEditor, ArticlesTable } from '@app/pages/articles-page/components'

interface props {}

const ArticlesPage: React.FC<props> = () => {
  const { data: response, isLoading, isError } = useArticles()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { data, ...pagination } = response!

  return (
    <React.Fragment>
      <PageInfo title={'Статьи'} />
      <PageHeader title={'Статьи'} extra={[<AddLink key={'1'} route={'addArticle'} />]} />
      <Tabs
        defaultActiveKey={'1'}
        tabBarExtraContent={{
          right: <SiteLink key={1} entityType={'press-centr/blog'} />,
        }}
      >
        <Tabs.TabPane key={'1'} tab={'Таблица'}>
          <ArticlesTable articles={data} pagination={pagination} />
        </Tabs.TabPane>
        <Tabs.TabPane key={'2'} tab={'Редактор страницы'}>
          <ArticlesPageEditor />
        </Tabs.TabPane>
      </Tabs>
    </React.Fragment>
  )
}

export default ArticlesPage
